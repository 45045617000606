function Footer() {
  const year = new Date().getFullYear();

  return (
    <section>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "grey",
          padding: "15px",
        }}
      >
        &copy; {year} Royal Cargo
      </div>
    </section>
  );
}
export default Footer;
