import React from "react";
import { useState } from "react";
import { Form, Button, Card, Row, Carousel, Modal } from "react-bootstrap";
import classes from "./css_modules/home.module.css";
import man from "./../images/man.jpg";
import trans from "./../images/trans.jpg";
import store from "./../images/store.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
  faCalendar,
  faSortAmountDown,
  faShip,
  faUser,
  faBarcode,
} from "@fortawesome/fontawesome-free-solid";
import history from "../components/history";
import { useHistory } from "react-router-dom";

function HomePage() {
  const [trackingNumber, setTrackingNumber] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState("Not Found");
  const [torchOn, setTorchOn] = useState(false);
  const nav = useHistory();
  const handleInput = (e) => {
    setTrackingNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert("the tracking number is: " + trackingNumber);
    history.push("/#/package/" + trackingNumber);
    window.location.reload();
  };

  const putModal = () => (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      show={show}
      onHide={() => setShow(false)}
    >
      <div style={{ height: "40vh" }}>
        <BarcodeScannerComponent
          width="100%"
          height="100%"
          torch={torchOn}
          onUpdate={(err, result) => {
            if (result) {
              setData(result.text);
              nav.push("package/" + result.text);
            } else setData("Not Found");
          }}
        />
      </div>
      <div style={{ padding: 7, display: "flex" }}>
        Place QRCode At The Center Of The Camera
      </div>
    </Modal>
  );

  return (
    <React.Fragment>
      <section className={classes.body}>
        <div className={classes.majorone}>
          <div className={classes.formholder}>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className={classes.label}>
                  Track your shipment <FontAwesomeIcon icon={faShip} />
                </Form.Label>
                <div className={classes.combine}>
                  <Form.Control
                    type="text"
                    placeholder="Enter your tracking number (s)"
                    value={trackingNumber}
                    style={{ height: "50px" }}
                    onChange={(e) => handleInput(e)}
                    required
                  />
                  <Button
                    variant="light"
                    className={classes.scanner}
                    style={{ height: "44px" }}
                    onClick={() => setShow(true)}
                  >
                    <FontAwesomeIcon
                      icon={faBarcode}
                      size="2x"
                      className={classes.icon}
                      style={{ fontSize: 20, color: "#424242" }}
                    />
                  </Button>
                  <Button
                    variant="danger"
                    type="submit"
                    className={classes.seb}
                    style={{ height: "44px" }}
                  >
                    To Follow
                  </Button>
                </div>
                <Form.Text className="text-muted">
                  Get a Qoute {">>>"}
                </Form.Text>
              </Form.Group>
            </Form>
          </div>
          <Row className={classes.rowit}>
            <Card
              style={{ width: "18rem", marginTop: "4px", marginBottom: "4px" }}
            >
              <FontAwesomeIcon
                icon={faCalendar}
                size="2x"
                className={classes.icon}
              />

              <Card.Body>
                <Card.Title>About Us</Card.Title>
                <Card.Text>Over Six Million Clients</Card.Text>
              </Card.Body>
            </Card>
            <Card
              style={{ width: "18rem", marginTop: "4px", marginBottom: "4px" }}
            >
              <FontAwesomeIcon
                icon={faSortAmountDown}
                size="2x"
                className={classes.icon}
              />
              <Card.Body>
                <Card.Title>Get a quote</Card.Title>
                <Card.Text>Estimate the cost and compare</Card.Text>
              </Card.Body>
            </Card>
            <Card
              style={{ width: "18rem", marginTop: "4px", marginBottom: "4px" }}
            >
              <FontAwesomeIcon
                icon={faUser}
                size="2x"
                className={classes.icon}
              />
              <Card.Body>
                <Card.Title>Contact Support</Card.Title>
                <Card.Text>submit an inquiry or complain</Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </div>
        <div className={classes.major2}>
          <div className={classes.he}>Shipping services</div>
          <Row style={{ justifyContent: "center" }}>
            <Card style={{ width: "14rem", borderColor: "green" }}>
              <Card.Body>
                <Card.Title>Documents and packages</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Parcel
                </Card.Subtitle>
                <Card.Text>At national and international level</Card.Text>
              </Card.Body>
            </Card>

            <Card style={{ width: "14rem" }}>
              <Card.Body>
                <Card.Title>Air freight</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Pallets, containers, goods
                </Card.Subtitle>
                <Card.Text>
                  Internationally <br />
                  Same day
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "14rem" }}>
              <Card.Body>
                <Card.Title>Sea freight</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Pallets, containers, goods
                </Card.Subtitle>
                <Card.Text>Internationally</Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "14rem" }}>
              <Card.Body>
                <Card.Title>Road freight</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Pallets, containers, goods
                </Card.Subtitle>
                <Card.Text>Internationally</Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "14rem" }}>
              <Card.Body>
                <Card.Title>Rail freight</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Pallets, containers, goods
                </Card.Subtitle>
                <Card.Text>Between Europe and Asia</Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </div>
        <div className={classes.major2}>
          <div className={classes.he}>Logistics solutions</div>
          <Row style={{ justifyContent: "center" }}>
            <Card style={{ width: "26rem", margin: "4px" }}>
              <Card.Img variant="top" src={man} />
              <Card.Body>
                <Card.Title> Contract logistics</Card.Title>
                <Card.Text>Learn more about contract logistics</Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "26rem", margin: "4px" }}>
              <Card.Img variant="top" src={store} />
              <Card.Body>
                <Card.Title>Warehousing solutions</Card.Title>
                <Card.Text>
                  Warehousing for SMEs <br /> Warehousing for businesses
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "26rem", margin: "4px" }}>
              <Card.Img variant="top" src={trans} />
              <Card.Body>
                <Card.Title>Transport and distribution</Card.Title>
                <Card.Text>
                  Transport management for SMEs <br /> Transport management for
                  companies
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </div>
        <div>
          <Carousel>
            <Carousel.Item interval={1000}>
              <img className="d-block w-100" src={man} alt="First slide" />
              <Carousel.Caption>
                <h3>Trucking</h3>
                {/* <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={500}>
              <img className="d-block w-100" src={trans} alt="Second slide" />
              <Carousel.Caption>
                <h3>Freight forwarding</h3>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={store} alt="Third slide" />
              <Carousel.Caption>
                <h3>Warehousing and distribution</h3>
                {/* <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>
      {putModal()}
    </React.Fragment>
  );
}
export default HomePage;
