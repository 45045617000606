import { useEffect, useState } from "react";
import axios from "axios";
import classes from "./update.module.css";
import { useParams } from "react-router-dom";
import history from "../../components/history";
import { backenUrl } from "../../utils/uris";

function UpdateShipment() {
  const getId = useParams();
  const id = getId.id;
  const [flightnum, setflightnum] = useState("");
  const [depairport, setdepairport] = useState("");
  const [destairport, setdestairport] = useState("");
  const [departuredate, setdeparturedate] = useState("");
  const [arrivaldate, setarrivaldate] = useState("");
  const [location, setlocation] = useState("");
  const [status, setstatus] = useState("");
  const handleFlight = (e) => {
    setflightnum(e.target.value);
  };
  const handleDepair = (e) => {
    setdepairport(e.target.value);
  };
  const handleDesport = (e) => {
    setdestairport(e.target.value);
  };
  const handleDepdate = (e) => {
    setdeparturedate(e.target.value);
  };
  const handleArrival = (e) => {
    setarrivaldate(e.target.value);
  };
  const handleLocation = (e) => {
    setlocation(e.target.value);
  };
  const handleStatus = (e) => {
    setstatus(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      travelFlightNumber: flightnum,
      departureAirport: depairport,
      destinationAirport: destairport,
      departureDate: departuredate,
      arrivalDate: arrivaldate,
      currentLocation: location,
      status: status,
    };
    console.clear();
    console.log(id);
    const response = await axios.put(backenUrl + "package/" + id, body);
    if (response.data.success) {
      alert("updated");
    } else {
      alert("update failed");
    }
  };

  const checkLogin = () => {
    const user = window.sessionStorage.getItem("user");
    if (user === null) {
      history.push("/#/admin");
      window.location.reload();
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <section className={classes.body}>
      <div className={classes.major}>
        <div className={classes.form}>
          <div className="card shadow-lg border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
            <div className="card-header bg-transparent border-0 text-center text-uppercase">
              <h3> Update Package </h3>
            </div>
            <div className="card-body">
              <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <formgroup>
                  <legend>Main Information</legend>
                  <div className="form-group">
                    <label className="mb-0">
                      Travel Flight Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Travel Flight Number"
                      value={flightnum}
                      onChange={(e) => handleFlight(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Airport of Departure<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Airport of Departure"
                      value={depairport}
                      onChange={(e) => handleDepair(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Airport of Destination
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Airport of Destination"
                      value={destairport}
                      onChange={(e) => handleDesport(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Estimated Departure Date
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Estimated Departure Date"
                      value={departuredate}
                      onChange={(e) => handleDepdate(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Estimated Arrival Date
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Estimated Arrival Date"
                      value={arrivaldate}
                      onChange={(e) => handleArrival(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Current Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Current Location"
                      value={location}
                      onChange={(e) => handleLocation(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Status<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="status"
                      value={status}
                      onChange={(e) => handleStatus(e)}
                    />
                  </div>
                </formgroup>

                <p className="text-center mb-0">
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg w-100 text-uppercase"
                    value="Submit Now"
                    style={{ marginTop: "15px" }}
                  />
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default UpdateShipment;
