function BreakLines(att) {
  return (
    <>
      <div>
        <hr
          style={{
            width: "30%",
            height: "6px",
            backgroundColor: att.color,
            display: "inline-block",
            margin: "3px",
          }}
        />
        <hr
          style={{
            width: "30%",
            height: "6px",
            backgroundColor: att.color,
            display: "inline-block",
            margin: "3px",
          }}
        />
        <hr
          style={{
            width: "30%",
            height: "6px",
            backgroundColor: att.color,
            display: "inline-block",
            margin: "3px",
          }}
        />
      </div>
    </>
  );
}

export default BreakLines;
