import { HashRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import MainNavigation from "./components/layout/MainNavigation";
import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import PackagePage from "./pages/PackagePage";
import AboutUsPage from "./pages/AboutUsPage";
import Footer from "./components/layout/Footer";
import ServicesPage from "./pages/ServicesPage";
import Login from "./pages/admin/Login";
import CreateShipment from "./pages/admin/CreateShipment";
import ViewShipments from "./pages/admin/ViewShipments";
import UpdateShipment from "./pages/admin/UpdateShipment";
function App() {
  return (
    <React.Fragment>
      <Router>
        <MainNavigation />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route
            exact
            path="/package/:trackingNumber"
            component={PackagePage}
          />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/services" component={ServicesPage} />
          <Route exact path="/admin" component={Login} />
          <Route
            exact
            path="/admin/register-shipment"
            component={CreateShipment}
          />
          <Route exact path="/admin/view-all" component={ViewShipments} />
          <Route path="/admin/update/:id" exact component={UpdateShipment} />
        </Switch>
      </Router>
      <Footer />
    </React.Fragment>
  );
}

export default App;
