import classes from "./view.module.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import history from "../../components/history";
import { backenUrl } from "../../utils/uris";

function ViewShipments() {
  const [shipments, setshipments] = useState([]);

  useEffect(() => {
    checkLogin();
    getShipments();
  }, []);

  const getShipments = async () => {
    const response = await axios.get(backenUrl + "package");
    setshipments(response.data.packs);
  };

  const handleDelete = async (id) => {
    const body = { id: id };
    const response = await axios.delete(backenUrl + "package" + id);
    if (response.data.deleted === true) {
      alert("delete successfull " + id);
    } else {
      alert("delete failed " + id);
    }
  };

  const handleUpdate = async (id) => {
    history.push("/#/admin/update/" + id);
    window.location.reload();
  };

  const checkLogin = () => {
    const user = window.sessionStorage.getItem("user");
    if (user === null) {
      history.push("/#/admin");
      window.location.reload();
    }
  };

  return (
    <section className={classes.body}>
      <div className={classes.major}>
        <div className={classes.mother}>
          {shipments.map((pack) => (
            <div className={classes.child}>
              <span className={classes.number}>
                Tracking number: {pack.trackingNumber}
              </span>
              <div className={classes.type}>
                package type: {pack.shipmentType}
              </div>
              <div className={classes.buttons}>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(pack.id)}
                  style={{ margin: 10 }}
                >
                  Delete
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleUpdate(pack.id)}
                  style={{ margin: 10 }}
                >
                  Update
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default ViewShipments;
