import {
  Navbar,
  Offcanvas,
  NavDropdown,
  Container,
  Nav,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import classes from "./Navbar.module.css";
import logo from "./../../images/logo.png";
function MainNavigation() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className={classes.nav}
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} width={80} height={30} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="ml-auto">
              <NavLink className={classes.linkprimary} to="/">
                Home
              </NavLink>
              <NavLink
                className={classes.linkprimary}
                to="/package/tracking number"
              >
                Track Package
              </NavLink>
              <NavLink className={classes.linkprimary} to="/about-us">
                About Us
              </NavLink>
              <NavLink className={classes.linkprimary} to="/services">
                Services
              </NavLink>
              <NavLink className={classes.linkprimary} to="/contact-us">
                Contact Us
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default MainNavigation;
