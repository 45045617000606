import { useEffect, useState } from "react";
import classes from "./create.module.css";
import axios from "axios";
import history from "../../components/history";
import { backenUrl } from "../../utils/uris";
function CreateShipment() {
  const [totalpackage, settotalpackage] = useState("");
  const [weight, setweight] = useState("");
  const [volume, setvolume] = useState("");
  const [shipmenttype, setshipmenttype] = useState("");
  const [flightnum, setflightnum] = useState("");
  const [depairport, setdepairport] = useState("");
  const [destairport, setdestairport] = useState("");
  const [departuredate, setdeparturedate] = useState("");
  const [arrivaldate, setarrivaldate] = useState("");
  const [location, setlocation] = useState("");
  const [status, setstatus] = useState("");
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [rname, setrname] = useState("");
  const [remail, setremail] = useState("");
  const [raddress, setraddress] = useState("");
  const [admin, setadmin] = useState(0);

  const handlePackage = (e) => {
    settotalpackage(e.target.value);
  };
  const handleWeight = (e) => {
    setweight(e.target.value);
  };
  const handleVolume = (e) => {
    setvolume(e.target.value);
  };
  const handleType = (e) => {
    setshipmenttype(e.target.value);
  };
  const handleFlight = (e) => {
    setflightnum(e.target.value);
  };
  const handleDepair = (e) => {
    setdepairport(e.target.value);
  };
  const handleDesport = (e) => {
    setdestairport(e.target.value);
  };
  const handleDepdate = (e) => {
    setdeparturedate(e.target.value);
  };
  const handleArrival = (e) => {
    setarrivaldate(e.target.value);
  };
  const handleLocation = (e) => {
    setlocation(e.target.value);
  };
  const handleStatus = (e) => {
    setstatus(e.target.value);
  };
  const handleName = (e) => {
    setname(e.target.value);
  };
  const handlePhone = (e) => {
    setphone(e.target.value);
  };
  const handleEmail = (e) => {
    setemail(e.target.value);
  };
  const handleAddress = (e) => {
    setaddress(e.target.value);
  };
  const handleRname = (e) => {
    setrname(e.target.value);
  };
  const handleRemail = (e) => {
    setremail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      totalPackages: totalpackage,
      weight: weight,
      volume: volume,
      shipmentType: shipmenttype,
      travelFlightNumber: flightnum,
      departureAirport: depairport,
      destinationAirport: destairport,
      departureDate: departuredate,
      arrivalDate: arrivaldate,
      currentLocation: location,
      status: status,
      name: name,
      phone: phone,
      email: email,
      address: address,
      rname: rname,
      remail: remail,
      admin: admin,
      raddress: raddress,
    };
    const response = await axios.post(backenUrl + "package/", body);
    console.log(JSON.stringify(response));
    alert("success, tracking number: " + response.data.pack.trackingNumber);
  };

  const checkLogin = () => {
    const user = window.sessionStorage.getItem("user");
    if (user === null) {
      history.push("/#/admin");
      window.location.reload();
    } else {
      const userDetails = JSON.parse(user);
      setadmin(userDetails.id);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <section className={classes.body}>
      <div className={classes.major}>
        <div className={classes.form}>
          <div className="card shadow-lg border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
            <div className="card-header bg-transparent border-0 text-center text-uppercase">
              <h3> New Package </h3>
            </div>
            <div className="card-body">
              <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <formgroup>
                  <legend>Shipment Specifics</legend>
                  <div className="form-group">
                    <label className="mb-0">
                      Total Packages<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Total Packages"
                      value={totalpackage}
                      onChange={(e) => handlePackage(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Weight<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Weight"
                      value={weight}
                      onChange={(e) => handleWeight(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Volume<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Volume"
                      value={volume}
                      onChange={(e) => handleVolume(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Shipment Type<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Shipment Type"
                      value={shipmenttype}
                      onChange={(e) => handleType(e)}
                    />
                  </div>
                </formgroup>
                <formgroup>
                  <legend>Main Information</legend>
                  <div className="form-group">
                    <label className="mb-0">
                      Travel Flight Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Travel Flight Number"
                      value={flightnum}
                      onChange={(e) => handleFlight(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Airport of Departure<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Airport of Departure"
                      value={depairport}
                      onChange={(e) => handleDepair(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Airport of Destination
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Airport of Destination"
                      value={destairport}
                      onChange={(e) => handleDesport(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Estimated Departure Date
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Estimated Departure Date"
                      value={departuredate}
                      onChange={(e) => handleDepdate(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Estimated Arrival Date
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Estimated Arrival Date"
                      value={arrivaldate}
                      onChange={(e) => handleArrival(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Current Location<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Current Location"
                      value={location}
                      onChange={(e) => handleLocation(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Status<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="status"
                      value={status}
                      onChange={(e) => handleStatus(e)}
                    />
                  </div>
                </formgroup>
                <formgroup>
                  <legend>Sender's Information</legend>
                  <div className="form-group">
                    <label className="mb-0">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => handleName(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Phone<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => handlePhone(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => handleEmail(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => handleAddress(e)}
                    />
                  </div>
                </formgroup>
                <formgroup>
                  <legend>Receiver's Information</legend>
                  <div className="form-group">
                    <label className="mb-0">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={rname}
                      onChange={(e) => handleRname(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={remail}
                      onChange={(e) => handleRemail(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-0">
                      Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Address"
                      value={remail}
                      onChange={(e) => setraddress(e.target.value)}
                    />
                  </div>
                </formgroup>

                <p className="text-center mb-0">
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg w-100 text-uppercase"
                    value="Submit Now"
                    style={{ marginTop: "15px" }}
                  />
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CreateShipment;
