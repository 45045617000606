import React from "react";
import { useState, useEffect, useRef } from "react";
import { Form, Button, Modal, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classes from "./css_modules/package.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import { useScreenshot } from "use-screenshot-hook";
import {
  pdf,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {
  faShip,
  faArrowRight,
  faArrowAltCircleRight,
  faPrint,
  faBarcode,
} from "@fortawesome/fontawesome-free-solid";
import BreakLines from "../components/BreakLines";
import { useHistory } from "react-router-dom";
import axios from "axios";

import logo from "./../images/logo.png";
import { backenUrl } from "../utils/uris";
import MapView from "../components/map/MapView";

export const savePdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

function PackagePage() {
  const params = useParams();
  const number = params.trackingNumber;
  const [trackingNumber, setTrackingNumber] = useState(number);
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const nav = useHistory();

  const [found, setFound] = useState(false);
  const [dilivered, setdilivered] = useState("");
  const [shipment, setshipment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleInput = (e) => {
    setTrackingNumber(e.target.value);
  };

  const imageRef = useRef(null);
  const { image, takeScreenshot } = useScreenshot({ ref: imageRef });

  useEffect(() => {
    if (imageRef && show) {
      takeScreenshot();
    }
  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = { trackingNumber: trackingNumber };
    const response = await axios.post(backenUrl + "package/track", body);
    // alert(response.data.presence);
    if (response.data.success) {
      setFound(true);
      setshipment(response.data.pack);
      if (shipment.deliveryDate === "n/a") {
        setdilivered("Still Transporting");
      } else {
        setdilivered(shipment.deliveryDate);
      }
    } else {
      setFound(false);
    }
  };
  const handleReceived = async () => {
    const body = { trackingNumber: trackingNumber };
    const response = await axios.post(backenUrl + "package/track", body);
    // alert(response.data.presence);
    if (response.data.success) {
      setFound(true);
      setshipment(response.data.pack);
      if (shipment.deliveryDate === "n/a") {
        setdilivered("Still Transporting");
      } else {
        setdilivered(shipment.deliveryDate);
      }
    } else {
      setFound(false);
    }
  };
  useEffect(() => {
    handleReceived();
  }, []);

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#f8f5ee",
      width: "100vw",
    },
    stripe: {
      backgroundColor: "#ece9e2",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "black",
      borderBottom: "1px solid #dbdbdb",
    },
    tr: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      color: "black",
      borderBottom: "1px solid #dbdbdb",
    },
    bigtd: {
      width: "55%",
      padding: 5,
      paddingLeft: 10,
      borderRight: "1px solid #dbdbdb",
    },
    smalltd: {
      width: "45%",
      padding: 5,
      paddingLeft: 10,
    },
  });

  const MyDocument = () => {
    return (
      <Document>
        <Page className="page" size="A4" style={styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: 10,
              justifyContent: "center",
            }}
          >
            <Image src={logo} style={{ width: 50, height: 35 }} />
            <Text style={{ fontSize: 25, marginLeft: 10 }}>ROYAL CARGOS</Text>
          </View>
          <View style={{ paddingTop: 15, paddingBottom: 25 }}>
            <Text
              style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
            >
              Shipment Specific Information
            </Text>
          </View>

          <View style={{}}>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Total packages</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.totalPackages}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Date of proof of delivery</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.deliveryDate}</Text>
              </View>
            </View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Weight</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.weight}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Volume (m³)</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.volume}</Text>
              </View>
            </View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Shipment Type</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.shipmentType}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Tracking Number </Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.trackingNumber}</Text>
              </View>
            </View>
          </View>

          <View style={{ paddingTop: 25, paddingBottom: 15 }}>
            <Text
              style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
            >
              Main Information
            </Text>
          </View>

          <View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Cargo Number</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.travelFlightNumber}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>From</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.departureAirport}</Text>
              </View>
            </View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>To</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.destinationAirport}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Estimated Departure Date</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.departureDate}</Text>
              </View>
            </View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Estimated Arrival Date</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.arrivalDate}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Current Location</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.currentLocation}</Text>
              </View>
            </View>
          </View>

          <View style={{ paddingTop: 25, paddingBottom: 15 }}>
            <Text
              style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
            >
              Sender's Information
            </Text>
          </View>

          <View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Name</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.name}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Email</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.email}</Text>
              </View>
            </View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Phone</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.phone}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Address</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.address}</Text>
              </View>
            </View>
          </View>
          <View style={{ paddingTop: 25, paddingBottom: 15 }}>
            <Text
              style={{ textAlign: "center", fontSize: 25, fontWeight: "bold" }}
            >
              Receiver's Information
            </Text>
          </View>
          <View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Name</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.rname}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Email</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.remail}</Text>
              </View>
            </View>
            <View style={styles.stripe}>
              <View style={styles.bigtd}>
                <Text>Phone</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.rphone}</Text>
              </View>
            </View>
            <View style={styles.tr}>
              <View style={styles.bigtd}>
                <Text>Address</Text>
              </View>
              <View style={styles.smalltd}>
                <Text>{shipment.raddress}</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const QRDocument = () => (
    <Document>
      <Page className="page" size="A4" style={{ backgroundColor: "white" }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 10,
            justifyContent: "center",
          }}
        >
          <Image src={logo} style={{ width: 50, height: 35 }} />
          <Text style={{ fontSize: 25, marginLeft: 10 }}>ROYAL CARGOS</Text>
        </View>
        <Image src={image} />
      </Page>
    </Document>
  );

  const saveQRCode = () => {
    if (image) {
      savePdf(<QRDocument />, trackingNumber + "_QR.pdf");
    } else {
      setTimeout(() => saveQRCode(), 200);
    }
  };

  const scanModal = () => (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <div style={{ height: "40vh" }}>
        <BarcodeScannerComponent
          width="100%"
          height="100%"
          // torch={torchOn}
          onUpdate={(err, result) => {
            if (result) {
              setData(result.text);
              nav.push("package/" + result.text);
            } else setData("Not Found");
          }}
        />
      </div>
      <div style={{ padding: 7, display: "flex" }}>
        Place QRCode At The Center Of The Camera
      </div>
    </Modal>
  );

  const putModal = () => (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="md"
      show={show}
      onHide={() => setShow(false)}
    >
      <div
        ref={imageRef}
        style={{
          height: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <QRCode value={trackingNumber} />
      </div>
      <div
        style={{ paddingBottom: 15, display: "flex", justifyContent: "center" }}
      >
        <button className="btn btn-primary" onClick={() => saveQRCode()}>
          Download QRCode
        </button>
      </div>
    </Modal>
  );

  return (
    <section className={classes.body}>
      <div className={classes.major}>
        <div>
          <div className={classes.he}>Tracking Receipt</div>
          <div className={classes.holder}>
            <div className={classes.formholder}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={classes.label}>
                    Enter your tracking number <FontAwesomeIcon icon={faShip} />
                  </Form.Label>
                  <div className={classes.combine}>
                    <Form.Control
                      type="text"
                      placeholder="Enter your tracking number (s)"
                      value={trackingNumber}
                      style={{ height: "50px" }}
                      onChange={(e) => handleInput(e)}
                    />
                    <Button
                      variant="light"
                      className={classes.scanner}
                      style={{ height: "44px" }}
                      onClick={() => setShowModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faBarcode}
                        size="2x"
                        className={classes.icon}
                        style={{ fontSize: 20, color: "#424242" }}
                      />
                    </Button>
                    <Button
                      variant="danger"
                      type="submit"
                      className={classes.seb}
                      style={{ height: "44px" }}
                    >
                      To Follow
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
            {!found ? (
              <div className={classes.notFound}> Sorry Package not found </div>
            ) : (
              <div className={classes.track}>
                <div className={classes.trackHead}>
                  <div className={classes.trackStatus}>{shipment.status}</div>
                  <div className={classes.trackDate}> {dilivered}</div>
                  <BreakLines color="darkgreen" />
                  <div className={classes.trackInfo1}>
                    This shipment is processed by: <b>DHL Global Forwarding</b>
                  </div>
                  <div className={classes.trackInfo2}>
                    Tracking number: <b>{shipment.trackingNumber}</b>
                  </div>
                  <div className={classes.trackInfo3}>
                    Customer service
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={classes.icon}
                    />
                  </div>
                  <div className={classes.destination}>
                    Comes From <FontAwesomeIcon icon={faArrowAltCircleRight} />{" "}
                    Going To
                  </div>
                  <Button
                    onClick={() =>
                      savePdf(<MyDocument />, trackingNumber + ".pdf")
                    }
                    variant="success"
                    style={{ height: "44px" }}
                  >
                    Print <FontAwesomeIcon icon={faPrint} />
                  </Button>
                  <Button
                    onClick={() => setShow(true)}
                    variant="outline-info"
                    style={{ height: 44, marginLeft: 10 }}
                  >
                    Get QRCode
                  </Button>
                  <div className={classes.shipment}>
                    <div className={classes.he}>
                      Shipment Specific Information
                    </div>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Total packages</td>
                          <td>{shipment.totalPackages}</td>
                        </tr>
                        <tr>
                          <td>Date of proof of delivery</td>
                          <td>{shipment.deliveryDate}</td>
                        </tr>
                        <tr>
                          <td>Weight</td>
                          <td>{shipment.weight}</td>
                        </tr>
                        <tr>
                          <td>Volume (m³)</td>
                          <td>{shipment.volume}</td>
                        </tr>
                        <tr>
                          <td>Shipment Type</td>
                          <td>{shipment.shipmentType}</td>
                        </tr>
                        <tr>
                          <td>Tracking Number</td>
                          <td>{shipment.trackingNumber}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className={classes.he}>Main Information</div>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Cargo Number</td>
                          <td>{shipment.travelFlightNumber}</td>
                        </tr>
                        <tr>
                          <td>From</td>
                          <td>{shipment.departureAirport}</td>
                        </tr>
                        <tr>
                          <td>To</td>
                          <td>{shipment.destinationAirport}</td>
                        </tr>
                        <tr>
                          <td>Estimated Departure Date</td>
                          <td>{shipment.departureDate}</td>
                        </tr>
                        <tr>
                          <td>Estimated Arrival Date</td>
                          <td>{shipment.arrivalDate}</td>
                        </tr>
                        <tr>
                          <td>Current Location</td>
                          <td>{shipment.currentLocation}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className={classes.he}>Sender's Information</div>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{shipment.name}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{shipment.email}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{shipment.phone}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>{shipment.address}</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className={classes.he}>Receiver's Information</div>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{shipment.rname}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{shipment.remail}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{shipment.rphone}</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>{shipment.raddress}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  {/* <MapView /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {putModal()}
      {scanModal()}
    </section>
  );
}
export default PackagePage;
