import classes from "./login.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import history from "../../components/history";
import { backenUrl } from "../../utils/uris";

function Login() {
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [loggedIn, setloggedIn] = useState(false);
  const [username, setusername] = useState("");

  const handleEmail = (e) => {
    setemail(e.target.value);
  };
  const handlePass = (e) => {
    setpass(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = { email: email, password: pass };
    const response = await axios.post(
      "https://logisticsbe-production.up.railway.app/api/admin/login",
      body
    );
    if (response.data.success) {
      setusername(response.data.admin.username);
      setloggedIn(true);
      alert("Welcome " + response.data.admin.username);
      // console.log(response);
      const user = {
        id: response.data.admin.id,
        username: response.data.admin.username,
      };

      window.sessionStorage.setItem("user", JSON.stringify(user));
    } else {
      alert("Invalid login details");
    }
  };
  const gotoShipments = () => {
    history.push("/#/admin/view-all");
    window.location.reload();
  };
  const gotoCreate = () => {
    history.push("/#/admin/register-shipment");
    window.location.reload();
  };

  const checkLogin = () => {
    const user = window.sessionStorage.getItem("user");
    if (user !== null) {
      const userDetails = JSON.parse(user);
      setusername(userDetails.name);
      setloggedIn(true);
    } else {
      setloggedIn(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);
  return (
    <section className={classes.body}>
      <div className={classes.form}>
        {loggedIn ? (
          <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
            <div className="card-header bg-transparent border-0 text-center text-uppercase">
              <h3> Hello {username} </h3>
            </div>
            <div className="card-body">
              <Button
                variant="primary"
                onClick={() => gotoShipments()}
                style={{ margin: 10 }}
              >
                {" "}
                All Shipments{" "}
              </Button>
              <Button
                variant="secondary"
                onClick={() => gotoCreate()}
                style={{ margin: 10 }}
              >
                New Shipment
              </Button>
            </div>
          </div>
        ) : (
          <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
            <div className="card-header bg-transparent border-0 text-center text-uppercase">
              <h3> Log In </h3>
            </div>
            <div className="card-body">
              <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label className="mb-0">
                    Email: <span className="text-danger">*</span>
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => handleEmail(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="mb-0">
                    Password: <span className="text-danger">*</span>
                  </label>
                  <input
                    name="contact"
                    type="password"
                    className="form-control"
                    placeholder="Contact"
                    value={pass}
                    onChange={(e) => handlePass(e)}
                  />
                </div>

                <p className="text-center mb-0" style={{ marginTop: "20px" }}>
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg w-100 text-uppercase"
                    value="Submit Now"
                  />
                </p>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
export default Login;
