import { useState } from "react";
import classes from "./css_modules/contact.module.css";
import axios from "axios";
import { Card, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faTruck,
  faShip,
  faUser,
} from "@fortawesome/fontawesome-free-solid";
import { backenUrl } from "../utils/uris";
function ContactUsPage() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");

  const handleName = (e) => {
    setname(e.target.value);
  };
  const handleEmail = (e) => {
    setemail(e.target.value);
  };
  const handlePhone = (e) => {
    setphone(e.target.value);
  };
  const handleMessage = (e) => {
    setmessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    };
    const response = await axios.post(backenUrl + "contact/", body);
    if (response.data.sent === true) {
      alert("message sent");
    } else {
      alert("sorry, an error occured");
    }
  };
  return (
    <section className={classes.body}>
      <div className={classes.major}>Contact Us</div>
      <div className={classes.form}>
        <Row className={classes.rowit}>
          <Card
            style={{ width: "18rem", marginTop: "4px", marginBottom: "4px" }}
          >
            <FontAwesomeIcon
              icon={faCalendar}
              size="2x"
              className={classes.icon}
            />

            <Card.Body>
              <Card.Title>Get Info</Card.Title>
              <Card.Text>
                <a
                  href="mailto:Info@royalcargos.com
"
                >
                  Info@royalcargos.com
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "18rem", marginTop: "4px", marginBottom: "4px" }}
          >
            <FontAwesomeIcon icon={faUser} size="2x" className={classes.icon} />
            <Card.Body>
              <Card.Title>Submit Queries</Card.Title>
              <Card.Text>
                <a
                  href="mailto:support@royalcargos.com
"
                >
                  support@royalcargos.com
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            style={{ width: "18rem", marginTop: "4px", marginBottom: "4px" }}
          >
            <FontAwesomeIcon
              icon={faTruck}
              size="2x"
              className={classes.icon}
            />
            <Card.Body>
              <Card.Title>Get a Qoute</Card.Title>
              <Card.Text>
                <a
                  href="mailto:transport@royalcargos.com
"
                >
                  transport@royalcargos.com
                </a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
        <>
          <div className="card shadow-sm border-0 px-3 rounded-2 mb-3 py-4 mx-auto mt-5 bg-light">
            <div className="card-header bg-transparent border-0 text-center text-uppercase">
              <h3> Contact Us</h3>
            </div>
            <div className="card-body">
              <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label className="mb-0">
                    Your name<span className="text-danger">*</span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => handleName(e)}
                    className="form-control"
                    placeholder="Name"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-0">
                    Your email<span className="text-danger">*</span>
                  </label>
                  <input
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => handleEmail(e)}
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-0">Your contact number (Optional)</label>
                  <input
                    name="contact"
                    type="text"
                    value={phone}
                    onChange={(e) => handlePhone(e)}
                    className="form-control"
                    placeholder="Contact"
                  />
                </div>
                <div className="form-group">
                  <label className="mb-0">
                    Message<span className="text-danger">*</span>
                  </label>
                  <textarea
                    name="message"
                    type="text"
                    value={message}
                    onChange={(e) => handleMessage(e)}
                    className="form-control"
                    placeholder="Message"
                  />
                </div>
                <p className="text-center mb-0">
                  <input
                    type="submit"
                    className="btn btn-primary btn-lg w-100 text-uppercase"
                    value="Send Now"
                  />
                </p>
              </form>
            </div>
          </div>
        </>
      </div>
    </section>
  );
}

export default ContactUsPage;
