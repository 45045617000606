import classes from "./css_modules/services.module.css";
import test from "./../images/store.jpg";
import { Card, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/fontawesome-free-solid";

function ServicesPage() {
  return (
    <section className={classes.body}>
      <div className={classes.major}>Our Services</div>
      <Row className={classes.services}>
        <div className={classes.left}>
          <div className={classes.he}>
            WE OFFER QUICK & POWERFUL LOGISTICS SOLUTION
          </div>
          <div className={classes.para}>
            Following standards set by the international committee, we provide
            optimum quality of service.
          </div>
        </div>
        <div className={classes.right}>
          <Row>
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    size="2x"
                    className={classes.icon}
                  />
                </div>
                <Card.Title>LAND FREIGHT</Card.Title>
                <Card.Text>
                  Secure interurban transportation of packages.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    size="2x"
                    className={classes.icon}
                  />
                </div>
                <Card.Title>AIR FREIGHT</Card.Title>
                <Card.Text>
                  Rapid delivery from anywhere in the world.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    size="2x"
                    className={classes.icon}
                  />
                </div>
                <Card.Title>SEA FREIGHT</Card.Title>
                <Card.Text>
                  Transport containers, pet, vehicles, and so much more.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    size="2x"
                    className={classes.icon}
                  />
                </div>
                <Card.Title>WAREHOUSE</Card.Title>
                <Card.Text>
                  Short-term and long-term storage of packages.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    size="2x"
                    className={classes.icon}
                  />
                </div>
                <Card.Title>CONSULTING</Card.Title>
                <Card.Text>
                  Efficiently plan and control your imports/exports.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: "20rem" }}>
              <Card.Body>
                <div style={{ textAlign: "center" }}>
                  <FontAwesomeIcon
                    icon={faTruck}
                    size="2x"
                    className={classes.icon}
                  />
                </div>
                <Card.Title>FAST DELEVARY</Card.Title>
                <Card.Text>
                  20 years in the business and we have never missed a deadline.
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </div>
      </Row>
    </section>
  );
}
export default ServicesPage;
