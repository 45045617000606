import classes from "./css_modules/about.module.css";
import { Row } from "react-bootstrap";
import cargoPic from "./../images/about-cargo.png";

function AboutUsPage() {
  return (
    <section className={classes.body}>
      <div className={classes.major}>About Us</div>
      <Row style={{ justifyContent: "center" }}>
        <img src={cargoPic} className={classes.sideImage} />
        <div className={classes.about}>
          <div className={classes.he}>ROYAL CARGO LOGISTICS</div>
          <div className={classes.para}>
            We started out as a small team with the vision of providing safe and
            affordable transportation to our clients. We constantly searched for
            ways to improve our quality of service, and today we can say we are
            the best in the business. Our network expands to over 120 countries,
            with over 400 clients.
          </div>
          <div className={classes.para}>
            Discover the fascinating world of logistics through anecdotes and
            expert perspectives. Discover through studies, reports and articles,
            the perspectives and the new innovative logistics solutions offered
            by DHL
          </div>
        </div>
      </Row>
    </section>
  );
}
export default AboutUsPage;
